<template>
    <div class="container">
        <md-dialog :md-active.sync="modEditaNota" :md-click-outside-to-close="false">
            <!-- <pre>{{ ItemEdit }}</pre> -->
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <!-- <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.numero}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <md-input type="number" v-model="ItemEdit[0].numero" class="form-control" />
                    </b-col>
                </b-row> -->
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.numero}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <!-- <md-input type="number" v-model="ItemEdit[0].numero" class="form-control" /> -->
                        <h5>{{ ItemEdit[0].numero }}</h5>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.nota}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <md-input type="text" v-model="ItemEdit[0].descripcion" class="form-control" />
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="guardaNota">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="closeModal">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
import Notas from '../../../services/Notas';

export default {
    name: 'EditaNota',
    props:['modEditaNota','ItemEdit'],
    data: () => ({

    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : 'Editar Nota',
                    numero : 'Número:',
                    nota : 'Nota:',
                    save : 'Guardar',
                    close : 'Cerrar'
                }
            }else{
                return {
                    title : 'Edit Note',
                    numero : 'Number:',
                    nota : 'Note:',
                    save : 'Save',
                    close : 'Close'
                }
            }
        }
    },
    methods:{
        async guardaNota(){
            // console.log('guardaNota')
            if(this.ItemEdit[0].numero == '' || this.ItemEdit[0].descripcion == ''){
                this.$toast.error("Campo(s) pendiente(s) por diligenciar")
            }else{
                this.ItemEdit[0].u_modifica = this.$store.state.userId
                // console.log(this.ItemEdit[0])
                let updatNota = await Notas.updateNota(this.ItemEdit[0])
                // console.log(updatNota)
                this.$toast.success(updatNota.data.message)
                this.$emit('refrescaTbl')
            }
        },
        closeModal(){
            this.$emit('closeModal',false)
        }
    }
}
</script>

<style>

</style>