import Api from '@/services/Api'

export default {
    getAllNotas(){
        return Api().get('/notas/getAllNotas')
    },
    getNotaById(id){
        return Api().get('/notas/getNotaById/'+id)
    },
    createNota(formu){
        return Api().post('/notas/createNota',formu)
    },
    updateNota(info){
        return Api().put('/notas/updateNota',info)
    },
    deleteNota(id){
        return Api().get('/notas/deleteNota/'+id)
    }
}
