import Api from '@/services/Api'

export default {
    getCodigoCompania(idCompania){
        return Api().get('getCodigoCompania/'+idCompania)
    },
    getNotasEF(idCompania,vigencia,clase){
        return Api().get('getNotasEF/'+idCompania+'/'+vigencia+'/'+clase)
    },
    getNotasDetalle(idCompania,vigencia,numero){
        return Api().get('getNotasDetalle/'+idCompania+'/'+vigencia+'/'+numero)
    },
    getBalanceNiif(idCompania,vigencia){
        return Api().get('balanceNiif/'+idCompania+'/'+vigencia)
    },
    getBalanceNiifConsol(idCompania,vigencia){
        return Api().get('balanceNiifConsol/'+idCompania+'/'+vigencia)
    },
    getBalanceNiifCompar(idCompania,vigencia){
        return Api().get('balanceNiifCompar/'+idCompania+'/'+vigencia)
    },
    getCompanias(individual){
        return Api().get('getCompanias/'+individual)
    },
    getFechas(){
        return Api().get('getFechas')
    },
    getReporteEsf(idCompania,vigencia){
        return Api().get('ReporteEsf/'+idCompania+'/'+vigencia)
    },
    getReporteEsfConsol(idCompania,vigencia){
        return Api().get('ReporteEsfConsol/'+idCompania+'/'+vigencia)
    },
    getReporteEsfCompar(idCompania,vigencia){
        return Api().get('ReporteEsfCompar/'+idCompania+'/'+vigencia)
    },
    getReporteEri(idCompania,vigencia){
        return Api().get('ReporteEri/'+idCompania+'/'+vigencia)
    },
    getReporteEriConsol(idCompania,vigencia){
        return Api().get('ReporteEriConsol/'+idCompania+'/'+vigencia)
    },
    getReporteEriCompar(idCompania,vigencia){
        return Api().get('ReporteEriCompar/'+idCompania+'/'+vigencia)
    },
    getReporteEfe(idCompania,vigencia){
        return Api().get('ReporteEfe/'+idCompania+'/'+vigencia)
    },
    getReporteEfeDirecto(idCompania,vigencia,nivel,consolidado){
        return Api().get('ReporteEfeDirecto/'+idCompania+'/'+vigencia+'/'+nivel+'/'+consolidado)
    },
    getReporteEfeIndirecto(idCompania,vigencia,nivel,consolidado){
        return Api().get('ReporteEfeIndirecto/'+idCompania+'/'+vigencia+'/'+nivel+'/'+consolidado)
    },
    getReporteInformacion(idCompania,vigencia,directo,u_crea){
        return Api().get('ReporteInformacion/'+idCompania+'/'+vigencia+'/'+directo+'/'+u_crea)
    },
    getReporteEcpInformacion(idCompania,vigencia,u_crea){
        return Api().get('ReporteEcpInformacion/'+idCompania+'/'+vigencia+'/'+u_crea)
    },
    grabaInformacion(idCompania,vigencia,form){
        return Api().post('GrabaInformacion/'+idCompania+'/'+vigencia,form)
    },
    grabaEcpInformacion(idCompania,vigencia,form){
        return Api().post('GrabaEcpInformacion/'+idCompania+'/'+vigencia,form)
    },
    getReporteEcp(idCompania,vigencia){
        return Api().get('ReporteEcp/'+idCompania+'/'+vigencia)
    },
    getReporteEcpConsol(idCompania,vigencia){
        return Api().get('ReporteEcpConsol/'+idCompania+'/'+vigencia)
    },
    getReporteEcpCompar(idCompania,vigencia){
        return Api().get('ReporteEcpCompar/'+idCompania+'/'+vigencia)
    },
    getReporteEcpFinal(idCompania,vigencia){
        return Api().get('ReporteEcpFinal/'+idCompania+'/'+vigencia)
    },
    getReporteEcpFinalConsol(idCompania,vigencia){
        return Api().get('ReporteEcpFinalConsol/'+idCompania+'/'+vigencia)
    },
    getEliminaciones(vigencia,opcion){
        return Api().get('getEliminaciones/'+vigencia+'/'+opcion)
    },
    getEliminaOrigen(vigencia,secuencia){
        return Api().get('getEliminaOrigen/'+vigencia+'/'+secuencia)
    },
    getEliminaDestino(vigencia,secuencia){
        return Api().get('getEliminaDestino/'+vigencia+'/'+secuencia)
    },
    grabeAjuste(tabla,form){
        return Api().post('grabeAjuste/'+tabla, form)
    },
    elimineAjuste(tabla,form){
        return Api().post('elimineAjuste/'+tabla, form)
    },
    getValorAjustar(vigencia,secuencia){
        return Api().get('getValorAjustar/'+vigencia+"/"+secuencia)
    },
    grabeContraAjuste(form){
        return Api().post('grabeContraAjuste', form)
    },
    getAjustesTopside(idCompania,vigencia,tipo_ajuste){
        return Api().get('getAjustesTopside/'+idCompania+'/'+vigencia+'/'+tipo_ajuste)
    },
    getCuentas(idCompania,vigencia){
        return Api().get('getCuentas/'+idCompania+'/'+vigencia)
    },
    getCuentasNiif(idCompania,vigencia,niif){
        return Api().get('getCuentasNiif/'+idCompania+'/'+vigencia+'/'+niif)
    },
    getNiif(vigencia,idCompania){
        return Api().get('getNiif/'+idCompania+'/'+vigencia)
    },
    getSaldoNiif(idCompania,vigencia,cuenta,niif){
        return Api().get('getSaldoNiif/'+idCompania+'/'+vigencia+'/'+cuenta+'/'+niif)
    },
    grabeAjusteTopside(idCompania,vigencia,form){
        return Api().post('grabeAjusteTopside/'+idCompania+'/'+vigencia, form)
    },
    borraAjusteTopside(idCompania,vigencia,secuencia,tipo_ajuste){
        return Api().post('borraAjusteTopside/'+idCompania+'/'+vigencia+'/'+secuencia+'/'+tipo_ajuste)
    },
    getEcpFilas(){
        return Api().get('getEcpFilas')
    },
    getEcpColumnas(){
        return Api().get('getEcpColumnas')
    },
    ingresaElimAjusteOrig(dataOrig){
        return Api().post('insertElimAjusteOrig',dataOrig)
    },
    ingresaElimAjusteDest(dataOrig){
        return Api().post('insertElimAjusteDest',dataOrig)
    },
}
