import { render, staticRenderFns } from "./EditaNota.vue?vue&type=template&id=ec179eec"
import script from "./EditaNota.vue?vue&type=script&lang=js"
export * from "./EditaNota.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports