<template>
    <div class="container">
        <md-dialog :md-active.sync="modNotaCuenta" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <!-- <pre> {{ InfoNota }} </pre> AAA
                <pre> {{ ItemVigCom }}</pre> BBB -->
                <!-- <pre>{{ ListCuentExist }}</pre> -->
                <b-row class="">
                    <b-col cols="3"></b-col>
                    <b-col cols="3" class="text-left">
                        <h5>{{labels.cuenta}}</h5>
                    </b-col>
                    <b-col cols="6" lg="6" class="text-left">
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item">
                                <select multiple v-model="listaCuentaSel" class="form-control">
                                    <option v-for="cuenta in listadoCuentas" :value="cuenta.cuenta" :key="cuenta.id">{{ cuenta.cuenta }} {{ cuenta.descripcion }}</option>
                                </select>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="guardaNota">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="closeModal">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
import NotasDetalle from '../../../services/NotasDetalle';
import PlanCuentas from '../../../services/PlanCuentas';

export default {
    name : 'AsocNotaCuenta',
    props : ['modNotaCuenta','InfoNota','ItemVigCom','ListCuentExist'],
    data : () => ({
        listaCuentaSel : '',
        listadoCuentas : []
    }),
    computed: {
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : 'Asociar cuenta',
                    save : 'Guardar',
                    close : 'Cerrar',
                    cuenta : 'Cuentas:'
                }
            }else{
                return {
                    title : 'Associate account',
                    save : 'Save',
                    close : 'Close',
                    cuenta : 'Accounts:'
                }
            }
        }
    },
    methods: {
        guardaNota(){
            // console.log('guardaNota')
            let formData = {
                'numero' : this.InfoNota[0].numero,
                'id_compania' : this.ItemVigCom.compania,
                'vigencia' : this.ItemVigCom.vigencia,
                'listCuentas' : this.listaCuentaSel,
                'idUser' : this.$store.state.userId
            }
            // console.log(formData)
            NotasDetalle.createNotaDetalle(formData)
            .then((res) => {
                this.$toast.success(res.data.message)
                this.$emit('refresTblDet',formData)
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setTimeout(() => {
                    this.$emit('refresTblDet',formData)
                }, 2000)
            })
        },
        closeModal(){
            // console.log('closeModal')
            this.$emit('closeModal',false)
        }
    },
    watch : {
        async modNotaCuenta(val){
            // let arrayExiste = ['1105010199','1105050100','1105050101','1105050103']
            let arrayExiste = []
            // let prue = []
            this.ListCuentExist.forEach(elem => {
                arrayExiste.push(elem.cuenta)
            });
            // console.log(arrayExiste)

            let cuentasList = []
            if(val){

                let cuentas = await PlanCuentas.planCuentPorCompaVig(this.ItemVigCom.compania,this.ItemVigCom.vigencia)
                cuentasList = cuentas.data.planCuentas

                const filteredArr = cuentasList.filter(element => !arrayExiste.includes(element.cuenta));
                // console.log(filteredArr);
                this.listadoCuentas = filteredArr
            }
        }
    }
}
</script>

<style>

</style>