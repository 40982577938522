<template>
    <div class="container">
        <md-dialog :md-active.sync="modCreaNota" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.numero}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <md-input type="number" v-model="numero" class="form-control" />
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.nota}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <md-input type="text" v-model="nota" class="form-control" />
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="guardaNota">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="closeModal">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
import Notas from '../../../services/Notas';

export default {
    name : 'CreaNotas',
    props: ['modCreaNota'],
    data: () => ({
        numero : '',
        nota : '',
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : 'Crear Nota',
                    numero : 'Número:',
                    nota : 'Nota:',
                    save : 'Guardar',
                    close : 'Cerrar'
                }
            }else{
                return {
                    title : 'Create Note',
                    numero : 'Number:',
                    nota : 'Note:',
                    save : 'Save',
                    close : 'Close'
                }
            }
        }
    },
    methods:{
        closeModal(){
            this.$emit('closeModal',false)
        },
        guardaNota(){
            // console.log('guardaNota')
            if(this.numero == '' || this.nota == ''){
                this.$toast.error("Campo(s) pendiente(s) por diligenciar")
            }else{
                let formData = {
                    'numero' : this.numero,
                    'nota' : this.nota,
                    'idUser' : this.$store.state.userId
                }
                // console.log(formData)
                Notas.createNota(formData)
                .then((res) => {
                    // console.log(res.data)
                    this.$toast.success(res.data.message)
                    this.$emit('refrescaTbl')
                })
                .catch((err) => {
                    this.$toast.error('El número ingresado ya existe.')
                    console.log(err)
                })
            }
        }
    }
}
</script>

<style>

</style>