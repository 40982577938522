import Api from '@/services/Api'

export default {
    getNotasDetalleByCompaniaVigenciaNota(compania,vigencia,nota){
        return Api().get('/notasDetalle/notasDetalleByCompaniaVigenciaNota/'+compania+'/'+vigencia+'/'+nota)
    },
    createNotaDetalle(formData){
        return Api().post('/notasDetalle/create',formData)
    },
    deleteNotaDetalle(id){
        return Api().delete('/notasDetalle/delete/'+id)
    }
}