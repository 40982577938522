<template>
    <div class="container">
        <div class="col-md-12">
            <h2> {{ labels.title }} </h2>
        </div>
        <div class="row justify-content-center mt-5">
            <div class="col-md-3 h5">
                <label for="selVigencia" class=""> {{ labels.validity }}</label>
                <select id="selVigencia" name="" v-model="selVigencia">
                    <option value="">{{ labels.select }}</option>
                    <option v-for="vigencia in listVigencia" :key="vigencia.vigencia"> {{ vigencia.vigencia }}</option>
                </select>
            </div>
        </div>
        <div class="row col-md-12 mt-2" v-if="showTblNotas">
            <div class="col-md-12 d-flex justify-content-end">
                <td>
                    <md-button style="background-color: #2e507a; width: 30px; height: 30px;" class="md-fab md-primary" @click="creaNota()">
                        <md-icon>add</md-icon>
                    </md-button>
                </td>
            </div>
            <div class="row col-md-12 justify-content-center">
                <md-table md-card md-fixed-header v-model="listaNotas">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell :md-label="labels.numero" class="border">{{ item.numero }}</md-table-cell>
                        <md-table-cell :md-label="labels.nota" class="text-left border">{{ item.descripcion }}</md-table-cell>
                        <md-table-cell :md-label="labels.asociaCuenta" class="border">
                            <md-button style="background-color: #2e507a; width: 30px; height: 30px;" class="md-fab md-primary" @click="showNota(item.id,item.numero,item.descripcion)">
                                <md-icon>list</md-icon>
                            </md-button>
                        </md-table-cell>
                        <md-table-cell :md-label="labels.edit" class="border">
                            <md-button style="background-color: #e5ac00; width: 30px; height: 30px;" class="md-fab md-primary" @click="editNota(item.id)">
                                <md-icon>edit</md-icon>
                            </md-button>
                        </md-table-cell>
                        <md-table-cell :md-label="labels.delete" class="border">
                            <md-button style="background-color: #cd2838; width: 30px; height: 30px;" class="md-fab md-primary" @click="deleteNota(item.id)">
                                <md-icon>delete</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
            </div>
        </div>
        <div class="row justify-content-center mt-5" v-if="showSelCompany">
            <div class="col-md-12 h5">
                <label for="selCompany" class=""> {{ labels.company }}</label>
                <select id="selCompany" name="" v-model="selCompany">
                    <option value="">{{ labels.select }}</option>
                    <option v-for="company in listCompany" :value="company.id" :key="company.id"> {{ company.nombre }}</option>
                </select>
            </div>
        </div>
        <div class="row col-md-12" v-if="showTblDetNotas">
            <div class="col-md-12 d-flex justify-content-end" v-if="listCuentaByNota != 0">
                <td>
                    <md-button style="background-color: #2e507a; width: 30px; height: 30px;" class="md-fab md-primary" @click="addNotaCuenta()">
                        <md-icon>add</md-icon>
                    </md-button>
                </td>
            </div>
            <div class="row col-md-12 justify-content-center" v-if="listCuentaByNota != 0">
                <md-table md-card md-fixed-header v-model="listCuentaByNota">
                    <md-table-toolbar>
                        <h1 class="md-title">{{ titleNota }}</h1>
                    </md-table-toolbar>
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell :md-label="labels.cuenta" class="border">{{ item.cuenta }}</md-table-cell>
                        <md-table-cell :md-label="labels.descripcion" class="text-left border">{{ item.descripcion }}</md-table-cell>
                        <md-table-cell :md-label="labels.delete" class="border">
                            <md-button style="background-color: #cd2838; width: 30px; height: 30px;" class="md-fab md-primary" @click="deleteDetNota(item.id,item.numero)">
                                <md-icon>delete</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
            </div>
            <div v-else class="row col-md-12 justify-content-center mt-3 h4">
                <div class="col-md-12">No hay datos</div>
                <div class="col-md-12 d-flex justify-content-end">
                    <td>
                        <md-button style="background-color: #2e507a; width: 30px; height: 30px;" class="md-fab md-primary" @click="addNotaCuenta()">
                            <md-icon>add</md-icon>
                        </md-button>
                    </td>
                </div>
            </div>
        </div>
        <CreaNota :modCreaNota="modCreaNota" @refrescaTbl="actualizaTbl" @closeModal="cierraModal"></CreaNota>
        <EditaNota :modEditaNota="modEditaNota" :ItemEdit="infoNota" @refrescaTbl="actualizaTbl" @closeModal="cierraModal"></EditaNota>
        <AsocNotaCuenta :modNotaCuenta="modNotaCuenta" :InfoNota="infoNota" :ItemVigCom="infoVigComp" :ListCuentExist="listCuentaByNota" @refresTblDet="updateTblDet" @closeModal="cierraModal"></AsocNotaCuenta>
        <InacticaItem :InactivaItem="deletFila" @confirmDelete="confirmDel" @cierraModal="closeMod"/>
    </div>
</template>

<script>

import Notas from '../../../services/Notas';
import PlanCuentas from '../../../services/PlanCuentas';
import CreaNota from './CreaNota.vue';
import EditaNota from './EditaNota.vue';
import InacticaItem from '../../utils/InacticaItem.vue'
import Company from '../../../services/Company'
import AsocNotaCuenta from './AsocNotaCuenta.vue';
import NotasDetalle from '../../../services/NotasDetalle';

export default {
    name: 'Notas',
    components: {
        CreaNota,EditaNota,InacticaItem,AsocNotaCuenta
    },
    data:() => ({
        isLoading : false,
        selVigencia : '',
        listVigencia : [],
        showTblNotas : false,
        listaNotas : [],
        modCreaNota : false,
        modEditaNota : false,
        infoNota : [],
        infoVigComp : {},
        deletFila : false,
        rowId : null,
        showSelCompany : false,
        selCompany : '',
        listCompany : [],
        showTblDetNotas : false,
        titleNota : '',
        listCuentaByNota : [],
        modNotaCuenta : false,
        delItemDet : false,
        itemDetNot : null,
        numNota : "",
        numNotaDet : "",
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : 'Notas',
                    validity : 'Vigencia',
                    select : 'Seleccione...',
                    numero : 'Número',
                    nota : 'Nota',
                    descripcion : 'Descripcion',
                    asociaCuenta : 'Asociar cuenta',
                    cuenta : 'Cuenta',
                    description : 'Descripción',
                    edit : 'Editar',
                    delete : 'Eliminar',
                    company : 'Compañia',
                }
            }else{
                return {
                    title : 'Notes',
                    validity : 'Validity',
                    select : 'Select...',
                    numero : 'Number',
                    nota : 'Note',
                    descripcion : 'Description',
                    asociaCuenta : 'Associate account',
                    cuenta : 'Account',
                    description : 'Description',
                    edit : 'Edit',
                    delete : 'Delete',
                    company : 'Company',
                }
            }
        }
    },
    methods:{
        closeMod(val){
            this.deletFila = val
        },
        cierraModal(val){
            this.modCreaNota = val
            this.modEditaNota = val
            this.modNotaCuenta = val
        },
        actualizaTbl(){
            Notas.getAllNotas()
            .then((res) => {
                // console.log(res.data)
                this.listaNotas = res.data.listNotas
                this.modCreaNota = false
                this.modEditaNota = false
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                this.isLoading = false;
            })
        },
        updateTblDet(info){
            // console.log(info.id_compania,info.vigencia)
            this.consDetallNota(info.id_compania,info.vigencia,info.numero)
            this.modNotaCuenta = false
        },
        creaNota(){
            // console.log('creaNota')
            this.modCreaNota = true
        },
        async editNota(id){
            // console.log(id)
            let dataEdit = await Notas.getNotaById(id)
            this.infoNota = dataEdit.data.qryNota
            this.modEditaNota = true
        },
        deleteNota(id){
            // console.log(id)
            this.rowId = id
            this.deletFila = true
        },
        deleteDetNota(id,numero){
            // console.log(id)
            this.itemDetNot = id
            this.numNotaDet = numero
            this.delItemDet = true
            this.deletFila = true
        },
        confirmDel(){
            if(this.delItemDet){
                // console.log('confirm')
                NotasDetalle.deleteNotaDetalle(this.itemDetNot)
                .then((res) => {
                    this.$toast.success(res.data.message)
                    this.consDetallNota(this.selCompany,this.selVigencia,this.numNotaDet)
                    this.deletFila = false
                })
                .catch((err) => console.log(err))

            }else{
                
                Notas.deleteNota(this.rowId)
                .then((res) => {
                    this.$toast.success(res.data.message)
                    this.actualizaTbl()
                    this.deletFila = false
                })
                .catch((err) => console.log(err))
            }
        },
        addNotaCuenta(){
            // console.log('addNotaCuenta')
            this.modNotaCuenta = true
        },
        async showNota(id,numero,nota){

            let dataNota = await Notas.getNotaById(id)
            this.infoNota = dataNota.data.qryNota
            this.titleNota = nota
            this.numNota = numero
            this.selCompany = ''
            this.showTblDetNotas = false

            Company.getAllCompanies()
            .then((result) => {
                // console.log(result)
                this.listCompany = result.data.companies
                this.showSelCompany = true
            })
            .catch((err) => {
                console.log(err)
            })
        },
        async consDetallNota(company,vigencia,numNota){
            // console.log(company,vigencia)
            try {
                let detalle = await NotasDetalle.getNotasDetalleByCompaniaVigenciaNota(company,vigencia,numNota)
                this.listCuentaByNota = detalle.data.notaDetal
            } catch (error) {
                console.log(error)
                this.listCuentaByNota = []
            }
        }
    },
    mounted(){
        PlanCuentas.getVigenciaPlancuentas()
        .then((res) => {
            this.listVigencia = res.data.getVigencia
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(() => {
            this.isLoading = false;
        })

        Notas.getAllNotas()
        .then((res) => {
            // console.log(res.data)
            this.listaNotas = res.data.listNotas
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(() => {
            this.isLoading = false;
        })
    },
    watch:{
        selVigencia(valNew){
            // console.log(valNew)
            if(valNew != ""){
                this.showTblNotas = true
            }else{
                this.showTblNotas = false
                this.showSelCompany = false
                this.$toast.error('Seleccione una vigencia')
            }
        },
        selCompany(valComp){
            // console.log(valComp)
            if(valComp != ""){
                this.showTblDetNotas = true
                this.infoVigComp.vigencia = this.selVigencia
                this.infoVigComp.compania = valComp
                this.consDetallNota(valComp,this.selVigencia,this.numNota)
            }else{
                this.showTblDetNotas = false
                this.$toast.error('Seleccione una compañia')
            }
        }
    }
}
</script>

<style>

</style>
